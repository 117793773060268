.register-hearder {
    background-color: #0B459C;
    width: 100%;
    height: 252px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-hearder-center {
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 2px 4px 2px rgba(10, 50, 108, 0.2);
    border-radius: 4px;
    width: 128px;
    height: 128px;
    margin-bottom: 16px;
}

.register-hearder-title {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
}

.date-input {
    display: flex;
    justify-content: space-around;
    padding: 8px;
    align-items: center;
}

.time-input {
    border-radius: 4px;
    border: 0.501186px solid rgba(0, 0, 0, 0.15);
    width: 156px;
    height: 32px;
    padding: 4px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
}

.time-- {
    color: rgba(0, 0, 0, 0.15);
}

.time-inputS {
    border-radius: 4px;
    width: 156px;
    height: 32px;
    padding: 4px;
    font-size: 14px;
    border: 1px solid #185CC3;
    color: #185CC3;
}

.text-gray {
    color: rgba(0, 0, 0, 0.15);
}

.text-gray-deep {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
}

.text-gray-deep2 {
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
}

.text-blue {
    color: #185CC3;
    font-size: 14px;
}

.adm-list-header,
.adm-form-footer {
    background: rgba(0, 0, 0, 0.04);
}

.margin-tm {
    margin-top: 17.5px;
    margin-bottom: 8px;
}

.adm-form-item-horizontal .adm-form-item-feedback-error {
    margin-left: -132px;
}

.passportNo .adm-list-item-content {
    border-top: none;
}

.passportPhoto .adm-list-item-content {
    border-top: none;
}

.img-underText {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
}

.adm-space {
    --gap-vertical: 0;
}

.tips {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-font {
    font-size: 10px;
}

.error-bg {
    background: #D1D1D1;
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.check {
    color: #F5278B;
    margin-top: 4px;
    font-size: 13px;
}

.success-title {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    padding: 18px 0 8px;
}

.success-content {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
}

.face-faild-title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #333333;
}

.face-faild-content {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #333333;
    padding-top: 8px;
}

.adm-form-item-feedback-error {
    color: #F5278B;
    ;
}

.adm-form-item.adm-form-item-vertical .adm-form-item-label {
    font-size: var(--adm-font-size-6);
}

.adm-radio.adm-radio-checked .adm-radio-icon {
    border-color: #185CC3;
    background-color: #185CC3;
}

.adm-form-item-child-inner:hover {
    color: #185CC3;
}

a {
    color: #185CC3;
}

.text-title {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.85);
}
.word-break{
    word-wrap: break-word;
}
.adm-toast-mask .adm-toast-main {
    width: auto;
    max-width: none;
}

.clause-title{
 font-size: 13px;
 font-weight: bold;
}
.clause-content{
font-size: 11px;
}
.clause-title2{
    font-size: 11px;
    text-decoration: underline;
 font-weight: bold;

}
.clause-logo{
    font-size: 10px;
    font-weight: bold;
}
 .adm-form-item-required-asterisk1 {
    /* position: absolute; */
    /* left: -0.6em; */
    /* top: 0; */
    font-family: SimSun, sans-serif;
    color: var(--adm-color-danger);
    -webkit-user-select: none;
    user-select: none;
}
/* .text-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 20%;
} */
.adm-form-item-child-inner{
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
    /* width: 20%; */
}
.center{
    /* position:relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* height: 100vh; */
    flex-direction: column;
}
/* .adm-spin-loading{
    position: absolute;
	left: 0;
	top: 0;
    right: 0;
    bottom: 0;
} */
.text-red{
    color: red;
    font-size: 18px;
}



