
.App{
  max-width: 680px;
  margin:0 auto;
}



.text-center{
  text-align: center;
}
/**/
.marginAuto {
  margin: 0 auto;
}

.box-shadow {
  box-shadow: 0 2px 4px 0 #999999;
  padding: 90px 5vw 5px;
  margin: -80px 5vw 20px;
  border-radius: 8px;
}

.form-shadow {
  box-shadow: 0 2px 4px 0 #999999;
  border-radius: 8px;
  padding: 50px 30px 10px;
  margin-top: 20px;
}
.marginR20{
  margin-right: 20px;
}
.marginT25{
  margin-Top: 25px;
}

@media screen and (max-width: 800px) {
  .avatar-box {
    width: 120px !important;
    text-align: center;
    margin: 0 auto;
  }

  .form {
    margin: 5vw auto;
  }

  .anticon-plus {
    display: none;
  }

  .ant-upload-text {
    display: none;
  }

  .avatar-uploader > .ant-upload {
    width: 120px !important;
    height: 120px !important;
    background: url("Images/avater.png") no-repeat center;
  }

  .ant-form-item-label > label::after {
    content: none;
  }

  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
  }
}


.padding30 {
  padding: 30px 30px 15px;
}

.btn-box {
  width: 100%;
  padding: 10px 5vw;
}

.btn-register {
  width: 100%;
}


#sex {
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 375px) {
  .logo {
   margin-top: 50px;
    width: 180px;
    height: auto;
  }
}
@media screen and (max-width: 320px) {
  .box-shadow {
    padding-top: 80px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .avatar-box{
    width: 100px !important;
  }
  .avatar-uploader > .ant-upload {
    width: 100px !important;
    height: 100px !important;
  }
  .Tips {
    margin-bottom: 10px;

  }
}

/**/

#imgs {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 100%
}

/**/

.info p {
  width: 100% !important;
}

.ant-modal-confirm-content {
  margin-left: 0 !important;
}

.Tips {
  margin-bottom: 16px;

  color: #999999;

}

.Tips2 {
  margin: -10px 0 5px;
  padding: 0 15px;
  font-size: 12px;
  color: #999999;
}

/*8888888888888888888888888888*/
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.react-html5-camera-photo > img, .react-html5-camera-photo > video {
  width: 90%;
}

/************camera*************/
.demo-image-preview {
  position: relative;
  text-align: center;
}
.demo-image-preview > img {
  width: 90%;
}
.anticon.anticon-delete{
  display: none;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  height: auto;

}
.info{
  height: 50vh !important;
  overflow: auto;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
}
.anticon.anticon-delete {
  display: flex;
}